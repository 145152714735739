.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 1000px;
  border-top: 1px solid var(--color-primary);
  padding-top: 3rem;
}

.experience__card {
  background: var(--color-bg-alt);
  border-radius: 1rem;
  padding: 2rem 3rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__card:hover {
  border-color: var(--color-primary-alt);
  cursor: default;
  scale: 1.07;
}

.experience__card h3 {
  color: var(--color-primary);
  margin-bottom: 2rem;
  transition: var(--transition);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details div {
  text-align: left;
}

.experience__details-icon {
  color: var(--color-primary-alt);
  margin-top: 6px;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
    width: 72%;
  }
  .experience__card {
    width: 80%;
    margin: 0 auto;
  }

  .experience__card:hover {
    scale: 1.05;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  .experience__card {
    width: 100%;
  }

  .experience__card:hover {
    scale: 1;
  }
}

@media screen and (max-width: 400px) {
  .experience__content {
    grid-template-columns: 1fr;
    margin: auto;
    width: 50%;
  }

  .experience__details {
    justify-content: left;
  }
}
