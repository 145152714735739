header {
  overflow: clip;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-alt);
}

.header__container {
  display: grid;
  grid-template-columns: 55% 30%;
  grid-gap: 15%;
  justify-content: center;
  width: 1000px;
}

.intro {
  text-align: left;
}

.intro h5:last-of-type {
  color: var(--color-primary);
  padding-bottom: 1rem;
}

.cta {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2.5rem;
}

.me {
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  transition: var(--transition);
}

.me:hover {
  transform: scale(1.07);
}

.header__socials {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 1rem;
}

.header__socials a {
  color: var(--color-primary-alt);
  transition: var(--transition);
  font-size: 2rem;
}

.header__socials a:hover {
  color: var(--color-primary);
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .header__container {
    width: 72%;
    grid-template-columns: 55% 40%;
    grid-gap: 5%;
  }

  .me {
    margin: 0 auto;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 800px) {
  header {
    align-items: flex-start;
    padding-top: 3rem;
  }

  .header__socials {
    margin-top: 2rem;
  }

  .header__socials a {
    font-size: 1.5rem;
  }

  .header__container {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
  }
  .intro {
    text-align: center;
  }

  .cta {
    justify-content: center;
    font-size: 0.8rem;
  }

  .me {
    width: 15rem;
    margin: 0 auto 3rem;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  header {
    padding-top: 2rem;
  }
  .header__container {
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }

  .header__socials a {
    font-size: 1.5rem;
  }
  .me {
    width: 13rem;
  }
}

@media screen and (max-width: 400px) {
  .me {
    width: 12rem;
  }
}
/*======================== MEDIA QUERIES (MINIMUM HEIGHT) ====================*/
@media screen and (max-height: 870px) and (min-width: 800px) {
  .header__socials {
    margin-top: 1rem;
  }
}

@media screen and (max-height: 870px) {
  header {
    height: 870px;
  }
}

/*<style>
  .overlay {
    position: relative;
    display: inline-block;
  }
  .overlay::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 128, 0, 0.5);
    z-index: 1;
  }
  .overlay img {
    position: relative;
    z-index: 2;
  }
</style>

<div class="overlay">
  <img src="your-image.jpg" alt="Your Image">
</div>*/
