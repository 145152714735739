* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: "Cascadia Code", "Space Grotesk", -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;
  margin: auto;
  background: var(--color-bg);
  color: var(--color-white);
  /*background-image: url("./assets/blue_grunge_pattern_background.jpg");*/
  line-height: 1.7;
}

@font-face {
  font-family: "Cascadia Code";
  src: url("./assets/CascadiaCode.ttf");
}

.container {
  max-width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
h1 {
  font-size: 2.5rem;
  padding: 1rem 0 1rem;
}

.title {
  width: 1000px;
  margin: 0 auto 1rem;
}

.title > h2,
.title > h5 {
  color: var(--color-primary-alt);
  text-align: left;
  grid-area: subtitle;
}

.title > h2 {
  color: var(--color-primary);
  display: flex;
  margin-bottom: auto;
}

a {
  color: var(--color-primary);
  transition: var(--transition);
  cursor: pointer;
}

a:hover {
  color: var(--color-primary-alt);
}

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: var(--color-primary-alt);
  font-weight: 500;
  transition: var(--transition);
  cursor: pointer;
  border: 1px solid var(--color-primary-alt);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary-alt);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

:root {
  --color-primary: #268de0;
  --color-primary-alt: #f75b1e;
  --color-white: #feefe7;
  --color-bg: #080b0a;
  --color-bg-alt: #233133;

  --container-width-lg: 100%;
  --container-width-md: 90%;
  --container-width-sm: 95%;

  --transition: all 400ms ease;

  --swiper-pagination-color: var(--color-primary);
  --swiper-navigation-color: var(--color-primary);
}

::-webkit-scrollbar {
  display: none;
}

#root {
  margin: auto;
}

section {
  padding: 10rem 0 10rem;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .container {
    max-width: var(--container-width-md);
  }

  h1 {
    font-size: 2rem;
  }

  section {
    margin-top: 6rem;
    padding: 2rem 0 2rem;
  }

  .title {
    max-width: 72%;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 800px) {
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  .container {
    max-width: var(--container-width-sm);
  }

  section {
    margin-bottom: 2rem;
  }

  .title {
    max-width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
