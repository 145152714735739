#contact {
  position: relative;
}

.message {
  opacity: 0;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  align-self: center;
  z-index: 100;
  transition: var(--transition);
}

.success {
  opacity: 1;
}

.contact__container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 1000px;
  border-top: 1px solid var(--color-primary);
  padding-top: 5rem;
}

form {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  width: 65%;
  gap: 1rem;
}

form button {
  width: 7rem;
}

form input,
form textarea {
  width: 100%;
  padding: 1rem 1.5rem;
  background: transparent;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  color: var(--color-primary-alt);
  resize: none;
}

form input:focus,
form textarea:focus {
  border-color: var(--color-primary-alt);
}

::-webkit-input-placeholder {
  color: var(--color-primary-alt);
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .contact__container {
    width: 72%;
    padding-top: 3rem;
  }

  form {
    width: 80%;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  .contact__container {
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  form {
    width: 100%;
  }
}
