footer {
  background-color: var(--color-bg-alt);
  text-align: center;
  padding: 3rem 2rem;
}

.footer__logo {
  margin-bottom: 2rem;
  display: inline-block;
  font-size: 2rem;
  font-weight: 500;
  background: var(--color-primary-alt);
  padding: 0.5rem 1rem;
  border-radius: 50%;
  color: var(--color-bg-alt);
}

.permalinks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 3rem;
  gap: 2rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.footer__socials a {
  background: var(--color-bg-alt);
  color: var(--color-primary);
  padding: 0.8rem 1rem 0.5rem;
  border-radius: 0.5rem;
  transition: var(--transition);
  border: 1px solid transparent;
  font-size: 1.5rem;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary-alt);
}

.footer__copyright {
  color: var(--color-primary);
}

.footer__copyright svg {
  position: relative;
  top: 0.125rem;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 800px) {
  footer {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
  }
}
