.projects__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  width: 1000px;
  border-top: 1px solid var(--color-primary);
  padding-top: 3rem;
}

.projects__item {
  background-color: var(--color-bg-alt);
  padding: 1.2rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.projects__item:hover {
  border-color: var(--color-primary-alt);
  scale: 1.05;
}

.projects__item-img {
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  overflow: hidden;
}

/*======================== OVERLAY ====================*/
/*
.overlay {
  position: relative;
  display: inline-block;
}

.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-alt);
  opacity: 0;
  z-index: 2;
}

.me img {
  position: relative;
  z-index: 1;
}
*/

.projects__item h3 {
  margin: 1.2rem 0;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .projects__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    width: 72%;
  }

  .projects__item:hover {
    scale: 1.03;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  .projects__container {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  .projects__item:hover {
    scale: 1;
  }
}

@media screen and (max-width: 400px) {
}
