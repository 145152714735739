#about {
  width: 100%;
  margin-top: 6rem;
}

.about__container {
  display: grid;
  grid-template-columns: 30% 60%;
  grid-gap: 10%;
  width: 1000px;
  border-top: 1px solid var(--color-primary);
  padding-top: 3rem;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 1rem;
}

.about__me-img {
  border-radius: 1rem;
  overflow: hidden;
  transition: var(--transition);
}

.about__me-img:hover {
  transform: scale(1.07);
}

.about__content {
  text-align: left;
}

p {
  margin: 0 0 2.6rem;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    grid-gap: 0;
    width: 72%;
  }

  .about__me {
    width: 15rem;
    margin: 0 auto 3rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 600px) {
  .about__container {
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  .about__me {
    width: 13rem;
    margin: 0 auto 3rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 400px) {
  .about__me {
    width: 12rem;
  }
}
