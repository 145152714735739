nav {
  background-color: rgba(8, 11, 10, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  backdrop-filter: blur(20px);
  z-index: 100;
  transition: var(--transition);
}

.nav__container {
  padding: 0.7rem 0;
  width: 1000px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

.hidden {
  top: -110px;
}

.hiddenBottom {
  bottom: -110px;
}

nav a {
  cursor: pointer;
  background: transparent;
  padding: 0.8rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  border: 2px solid var(--color-primary-alt);
  color: var(--color-primary);
}

nav a svg {
  display: none;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .nav__container {
    width: 72%;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 800px) {
  nav {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    border-radius: 1rem 1rem 0 0;
  }

  .nav__container {
    width: 370px;
    gap: 1rem;
  }

  nav a {
    border-radius: 0.5rem;
    width: 3.5rem;
  }

  nav a svg {
    display: block;
    margin: auto;
  }

  nav a span {
    display: none;
  }

  nav.hidden {
    top: auto;
    bottom: -110px;
  }
}

@media screen and (max-width: 400px) {
}
/*======================== MEDIA QUERIES (MINIMUM HEIGHT) ====================*/
@media screen and (max-height: 830px) {
}
