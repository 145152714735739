.certifications__container {
  width: 1000px;
  border-top: 1px solid var(--color-primary);
  padding-top: 7rem;
}

.certifications__slider {
  display: flex;
  overflow-x: auto;
  max-width: 35rem;
  padding-bottom: 5rem;
}

.certifications__item {
  background-color: transparent;
  padding-bottom: 3.5rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.certifications__item:hover > a {
  color: var(--color-primary);
}

.certifications__item a {
  color: var(--color-white);
}

.certifications__item a h3 {
  margin-top: 1rem;
}

.swiper-pagination-bullet {
  background-color: var(--color-primary) !important;
}

/*======================== MEDIA QUERIES (MEDIUM DEVICES) ====================*/
@media screen and (max-width: 1024px) {
  .certifications__container {
    width: 72%;
  }

  .certifications__slider {
    max-width: 25rem;
  }

  .certifications__item a h3 {
    display: none;
  }
}

/*======================== MEDIA QUERIES (SMALL DEVICES) ====================*/
@media screen and (max-width: 690px) {
}
@media screen and (max-width: 600px) {
  .certifications__container {
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  .certifications__slider {
    max-width: 100%;
  }
}
